<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
import { relateTypeList, pushPlat, goodsTypeList } from '@/utils/textFile'
import moment from 'moment'

export default {
  name: 'appPush',
  data() {
    return {
      ...api.command.getState(),
      linkAddressArr: [],
      linkModuleArr: [],
    }
  },
  mounted() {
    this.getDataInit()
    this.getInit()
  },
  methods: {
    getDataInit() {
      getAction('/config/farmGoodsCategory/list', {}, '/api').then((r) => {
        if (r.code == 200) {
          this.linkAddressArr = r.data.map((e) => {
            return { ...e, value: e.id }
          })
        }
      })
      getAction('/config/farmKingKongItem/list', {}, '/api').then((r) => {
        if (r.code == 200) {
          this.linkModuleArr = r.data.map((e) => {
            return { ...e, value: e.code }
          })
        }
      })
    },
    getInit() {
      api.command.getList.call(this, {
        url: '/farmPush/page',
        current: 1,
      })
    },
    getHeader() {
      return [
        {
          name: '二级商户名',
          key: 'subMerName',
          type: 'input',
        },
        {
          name: '电话',
          type: 'input',
          key: 'mobilePhone',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'plat',
          title: '推送端',
          align: 'left',
          sorter: (a, b) => a.plat - b.plat,
          customRender: (text) => {
            return (pushPlat.find((r) => r.value == text) || { name: '' }).name
          },
          onExport: (text) => {
            return (pushPlat.find((r) => r.value == text) || { name: '' }).name
          },
        },
        {
          dataIndex: 'content',
          title: '推送内容',
          align: 'left',
          sorter: (a, b) => a.content.length - b.content.length,
        },
        {
          dataIndex: 'linkType',
          title: '推送链接',
          align: 'left',
          sorter: (a, b) => a.linkType - b.linkType,
          customRender: (text) => {
            return (relateTypeList.find((r) => r.value == text) || { name: '' }).name
          },
          onExport: (text) => {
            return (relateTypeList.find((r) => r.value == text) || { name: '' }).name
          },
        },
        {
          dataIndex: 'sendTime',
          title: '定时时间',
          align: 'left',
          sorter: (a, b) => moment(a.sendTime).unix() - moment(b.sendTime).unix(),
        },
        {
          dataIndex: 'pushTime',
          title: '推送时间',
          align: 'left',
          sorter: (a, b) => moment(a.pushTime).unix() - moment(b.pushTime).unix(),
        },
        {
          dataIndex: 'status',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '未推送',
              value: '0',
            },
            {
              text: '已推送',
              value: '1',
            },
          ],
          onExport: (text) => {
            return ['未推送', '已推送'][Number(text)]
          },
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '未推送' : '已推送',
              color: data == 0 ? 'red' : 'green',
            }
          },
          filterMultiple: false,
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                display: records.status == 0,
                name: '编辑',
                onClick: () => {
                  getAction('/farmPush/detail?id=' + records.id, {}, '/api').then((r) => {
                    if (r.code == 200) {
                      this.handlePopup(r.data)
                    }
                  })
                },
              },
              {
                display: records.status == 0,
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/farmPush?id=${records.id}`,
                  }),
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    getDrawerForm(source, formData) {
      const form = [
        {
          title: '推送设置',
          form: [
            {
              name: '版本分类',
              key: 'plat',
              type: 'radioButton',
              width: '100%',
              labelAlign: 'left',
              typeData: pushPlat,
            },
            {
              name: '发送方式',
              type: 'row',
              cols: 24,
              labelAlign: 'left',
              gutter: 5,
              children: [
                {
                  shown: true,
                  label: '发送方式',
                  key: 'sendType',
                  type: 'select',
                  placeholder: '请选择发送方式',
                  cols: 8,
                  typeData: [
                    {
                      name: '立即',
                      value: '0',
                    },
                    {
                      name: '定时',
                      value: '1',
                    },
                  ],
                },
                {
                  shown: formData.sendType == 1,
                  label: '发送开始时间',
                  type: 'datePicker',
                  cols: 16,
                  key: 'sendTime',
                  props: {
                    showTime: true,
                    format: 'YYYY-MM-DD HH:mm:ss',
                    valueFormat: 'YYYY-MM-DD HH:mm:ss',
                    placeholder: '请选择发送开始日期',
                  },
                },
                // {
                //   label: '发送开始时间',
                //   type: 'timePicker',
                //   cols: 8,
                //   key: 'giveStartTime',
                //   props: {
                //     format: 'HH:mm',
                //     valueFormat: 'YYYY-MM-DD HH:mm:ss',
                //     allowClear: false,
                //     placeholder: '请选择发送开始时间'
                //   }
                // }
              ].filter((e) => e.shown),
            },
          ],
        },
        {
          title: '推送链接',
          form: [
            {
              label: '推送链接',
              type: 'row',
              cols: 24,
              gutter: 5,
              labelAlign: 'left',
              children: [
                {
                  name: '链接方式',
                  type: 'select',
                  labelAlign: 'left',
                  key: 'linkType',
                  typeData: relateTypeList,
                  onChange: (e) => {
                    formData.linkName = ''
                    formData.linkId = ''
                  },
                },
                ...[
                  // 外链
                  {
                    name: '链接地址',
                    type: 'input',
                    key: 'linkUrl',
                    display: formData.linkType == '1',
                    labelAlign: 'left',
                  },
                  // 内部链接
                  {
                    name: '链接模块',
                    type: 'select',
                    display: formData.linkType == '2',
                    key: 'linkId',
                    placeholder: '请选择链接模块',
                    defaultValue: 'eat_drink',
                    typeData: this.linkModuleArr,
                    labelAlign: 'left',
                  },
                  {
                    name: '链接地址',
                    type: 'select',
                    key: 'categoryId',
                    display: formData.linkType == '2' && formData.linkId == 'product_type',
                    typeData: this.linkAddressArr,
                    labelAlign: 'left',
                  },
                  // 商品
                  {
                    name: '链接地址',
                    type: 'select',
                    key: 'goodsType',
                    display: formData.linkType == '3',
                    defaultValue: '0',
                    typeData: goodsTypeList(),
                    labelAlign: 'left',
                  },
                  {
                    name: '链接地址',
                    type: 'storeSearch',
                    key: 'linkName',
                    valueKey: 'linkId',
                    display: formData.linkType == '3',
                    url: `/api/config/farmKingKong/listGoodsByType?type=${formData.goodsType}`,
                    labelAlign: 'left',
                  },
                  // 店铺
                  {
                    name: '链接地址',
                    type: 'storeSearch',
                    key: 'linkName',
                    valueKey: 'linkId',
                    display: formData.linkType == '4',
                    url: `/api/commodity/farmMainBody/list?mainBodyName=${formData.linkName}`,
                    labelAlign: 'left',
                  },
                  // 活动
                  {
                    name: '链接地址',
                    type: 'storeSearch',
                    key: 'linkName',
                    valueKey: 'linkId',
                    display: formData.linkType == '5',
                    url: '/api/farmActivity/list?appCode=sznc',
                    labelAlign: 'left',
                  },
                  // 直播
                  {
                    name: '链接地址',
                    type: 'storeSearch',
                    key: 'linkName',
                    valueKey: 'linkId',
                    display: formData.linkType == '6',
                    url: '/api/market/farmLiveRoom/list',
                    labelAlign: 'left',
                  },
                  // 专题页
                  {
                    name: '链接地址',
                    type: 'storeSearch',
                    key: 'linkName',
                    valueKey: 'linkId',
                    display: formData.linkType == '7',
                    url: '/api/farmShowSpecial/getList',
                    labelAlign: 'left',
                  },
                  // 抽奖
                  {
                    name: '链接地址',
                    type: 'storeSearch',
                    key: 'linkName',
                    valueKey: 'linkId',
                    display: formData.linkType == '8',
                    url: '/api/farmActivityDrawPrize/list',
                    labelAlign: 'left',
                  },
                  // 跳转小程序
                  {
                    name: 'appid',
                    type: 'input',
                    key: 'linkUrl',
                    display: formData.linkType == '9',
                    labelAlign: 'left',
                  },
                ].filter((e) => e.display == true),
              ],
            },
          ],
        },
        {
          title: '内容',
          form: [
            {
              name: '主标题',
              key: 'title',
              labelAlign: 'left',
              type: 'input',
            },
            {
              name: '内容',
              key: 'content',
              labelAlign: 'left',
              type: 'textArea',
            },
          ],
        },
      ]
      return form
    },
    handlePopup(dataSourse = {}) {
      let that = this
      apiTool.showDrawer({
        title: '推送设置',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data: (formData) => that.getDrawerForm(dataSourse, formData),
          form: dataSourse,
          formProps: {
            labelCol: { span: 3 },
            wrapperCol: { span: 21 },
          },
        },
        success: ({ data, setHidden }) => {
          api.command[data.id ? 'edit' : 'create']
            .call(this, {
              url: '/farmPush',
              params: {
                ...data,
              },
            })
            .then(setHidden)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.handlePopup()
          },
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.delPut.call(this, {
                  url: '/farmPush/deleteBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
    getDrawerForm2(data) {
      const form = [
        {
          title: '推送信息',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '版本分类',
                  value: (pushPlat.find((r) => r.value == data.plat) || { name: '' }).name,
                  width: '100%',
                  shown: true,
                },
                {
                  name: '链接方式',
                  value: (
                    relateTypeList.find((r) => r.value == data.linkType) || {
                      name: '',
                    }
                  ).name,
                  shown: true,
                },
                {
                  name: '链接地址',
                  value: data.linkUrl,
                  shown: data.linkUrl,
                },
                {
                  name: '发送方式',
                  width: '100%',
                  value: ['立即发送', '定时发送'][Number(data.sendType)],
                  shown: true,
                },
                {
                  name: '发送时间',
                  value: data.sendTime,
                  shown: data.sendType == 1,
                },
              ].filter((e) => e.shown),
            },
          ],
        },
        {
          title: '内容',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '标题',
                  type: 'text',
                  value: data.title,
                  width: '100%',
                },
                {
                  name: '内容',
                  type: 'text',
                  value: data.content,
                  width: '100%',
                },
              ],
            },
          ],
        },
        {
          title: '状态',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '推送',
                  type: 'text',
                  value: ['未推送', '已推送'][Number(data.status)],
                  shown: true,
                },
                {
                  name: '推送时间',
                  type: 'text',
                  value: data.pushTime,
                  shown: data.status == 1,
                },
              ].filter((e) => e.shown),
            },
          ],
        },
      ]
      return form
    },
    onRowSelect(records) {
      getAction('/farmPush/detail?id=' + records.id, {}, '/api').then((r) => {
        if (r.code == 200) {
          let a = r.data
          const data = this.getDrawerForm2(a)
          apiTool.showDrawer({
            title: '详情',
            width: '700px',
            view: DrawerForm,
            viewProps: { data, form: a },
          })
        }
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        // headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        onSelectRow={this.onRowSelect}
      />
    )
  },
}
</script>
